import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import useResizeObserver from 'use-resize-observer'
export type BasicChartData = { date: string; value: number }
import nlLocale from 'date-fns/locale/nl'
import { format } from 'date-fns'

type Props = {
  data: BasicChartData[]
  dataArea: BasicChartData[]
}

const render = (
  data: BasicChartData[],
  dataArea: BasicChartData[],
  ref: React.MutableRefObject<HTMLDivElement | null>,
  width: number,
) => {
  const height = 400
  const margin = { top: 50, bottom: 40, left: 50, right: 20 }

  d3.select(ref.current).select('.tooltip').remove()
  const tooltip = d3
    .select(ref.current)
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('fill', 'white')
    .style('border', '#416f7e')
    .style('border-style', 'solid')
    .style('border-width', '1px')
    .style('background-color', 'white')
    .style('border-radius', '6px')
    .style('padding', '7px')
    .style('font-size', '12px')
    .style('display', 'none')
    .style('pointer-events', 'none')
    .style('top', '-10px')

  d3.select(ref.current).select('svg').remove()
  const min = data.reduce(
    (min, d) => Math.min(min, new Date(d.date).getTime()),
    Infinity,
  )
  const max = data.reduce(
    (max, d) => Math.max(max, new Date(d.date).getTime()),
    0,
  )

  /* Scale */
  const xScale = d3
    .scaleLinear()
    .domain([min, max])
    .range([0, width - margin.left - margin.right])

  const yScale = d3
    .scaleLinear()
    .domain([0, data.reduce((max, d) => Math.max(max, d.value), 0)])
    // .domain([0, d3.max(data, d => d?.value)])
    .range([height - margin.top - margin.bottom, 0])

  /* Add SVG */
  const svg = d3
    .select(ref.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right + 'px')
    .attr('height', height + margin.top + margin.bottom + 'px')
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  svg
    .append('g')
    .attr('transform', `translate(0, ${height - margin.bottom - margin.top})`)
    .call(
      d3
        .axisBottom(xScale)
        .tickValues(data.map((d) => new Date(d.date).getTime()))
        .tickFormat((d) => {
          return `${format(new Date(d.valueOf()), 'MMM yyyy', {
            locale: nlLocale,
          })}`
        }),
    )
    .selectAll('text')
    .style('text-anchor', 'end')
    .attr('dx', '-.8em')
    .attr('dy', '.15em')
    .attr('transform', 'rotate(-45)')

  svg.append('g').call(
    d3
      .axisLeft(yScale)
      // .ticks(6)
      .tickSize(0)
      .tickFormat((d) => `€${d}`),
  )

  svg
    .append('text')
    .attr('text-anchor', 'end')
    .attr('x', 0)
    .attr('y', -40)
    .attr('transform', 'rotate(-90)')
    .attr('fill', '#000')
    .style('font-size', '10px')
    .text('duizenten')

  // add line
  svg
    .append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', '#0b132b')
    .attr('stroke-width', 2)
    .attr(
      'd',
      d3
        .line<BasicChartData>()
        .x((d) => xScale(new Date(d.date).getTime()))
        .y((d) => yScale(d.value))
        .curve(d3.curveCatmullRom),
    )

  //add gradient to area
  const areaGradient = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', 'areaGradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '0%')
    .attr('y2', '100%')

  areaGradient.append('stop').attr('offset', '0%').attr('stop-color', '#416f7e')
  areaGradient
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#abc9d4')

  // add area
  svg
    .append('path')
    .datum(dataArea)
    .style('fill', 'url(#areaGradient)')
    .attr('stroke', 'none')
    .attr(
      'd',
      d3
        .area<BasicChartData>()
        .x((d) => xScale(new Date(d.date).getTime()))
        .y0(yScale(0))
        .y1((d) => yScale(d.value)),
    )

  svg
    .selectAll('myCircles')
    .data(data)
    .enter()
    .append('circle')
    .attr('fill', '#0b132b')
    .attr('stroke', 'none')
    .attr('cx', function (d) {
      return xScale(new Date(d.date).getTime())
    })
    .attr('cy', function (d) {
      return yScale(d.value)
    })
    .attr('r', 3)

  svg
    .append('g')
    .attr('color', '#394149')
    .call(
      d3
        .axisLeft(yScale)
        .ticks(5)
        .tickSize(0)
        .tickFormat((d) => `€${d}`),
    )

  const focus = svg.append('g').style('display', 'none')

  // add vertical line
  focus
    .append('line')
    .attr('class', 'x')
    .style('stroke', '#abc9d4')
    .style('opacity', 0.3)
    .attr('y1', 0)
    .attr('y2', height - margin.bottom - margin.top)

  focus
    .append('circle')
    .attr('class', 'y')
    .style('fill', 'none')
    .style('stroke', '#416f7e')
    .style('opacity', 0.5)
    .attr('r', 4)
    .attr('y', 0)

  svg
    .append('rect')
    .attr('width', width)
    .attr('height', height)
    .style('fill', 'none')
    .style('pointer-events', 'all')
    .on('mouseover', function () {
      focus.style('display', null)
    })
    .on('mouseout', function () {
      focus.style('display', 'none')
    })
    .on('mousemove', mousemove)

  function mousemove(event: any) {
    const xMousePosition = d3.pointer(event, svg.node())[0] // gets [x,y]

    const currentDate = format(
      new Date(xScale.invert(xMousePosition)),
      'yyyy-MM-dd',
    ) // converts x to date

    const hoverElement = dataArea.find((d) => d.date === currentDate)
    const hoverElementInvestment = data.find((d) => d.date === currentDate)
    const xCoordinate =
      hoverElement && xScale(new Date(hoverElement.date).getTime())

    if (hoverElement && hoverElementInvestment && xCoordinate !== undefined) {
      focus
        .select('circle.y')
        .attr(
          'transform',
          `translate(${xCoordinate || 1}, ${yScale(hoverElement.value)})`,
        )
      focus
        .select('circle.y')
        .attr(
          'transform',
          `translate(${xCoordinate || 1}, ${yScale(
            hoverElementInvestment.value,
          )})`,
        )

      focus
        .select('.x')

        .attr('transform', `translate(${xCoordinate || 1},0)`)
        .attr('y2', height - margin.bottom - margin.top)

      tooltip
        .style('left', `${xCoordinate}px`)
        .style('display', 'block')
        .html(
          `Investeringen: € ${new Intl.NumberFormat('nl-NL').format(
            hoverElement.value * 1000,
          )}
          <br/>Fondswaarde: € ${new Intl.NumberFormat('nl-NL').format(
            hoverElementInvestment.value * 1000,
          )}
          <br/>Date: ${format(new Date(hoverElement.date), 'MMM yyyy', {
            locale: nlLocale,
          })}
          `,
        )

      focus.style('display', null)
    }
  }
  svg
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 380)
    .attr('r', 4)
    .style('fill', '#416f7e')
  svg
    .append('text')
    .attr('x', 20)
    .attr('y', 380)
    .text('Investeringen')
    .style('font-size', '10px')
    .attr('alignment-baseline', 'middle')
  svg
    .append('circle')
    .attr('cx', 100)
    .attr('cy', 380)
    .attr('r', 4)
    .style('fill', '#0b132b')
  svg
    .append('text')
    .attr('x', 120)
    .attr('y', 380)
    .text('Fondswaarde')
    .style('font-size', '10px')
    .attr('alignment-baseline', 'middle')
}

const FundChart: React.FC<Props> = ({ data, dataArea }) => {
  const svgRef = useRef<HTMLDivElement | null>(null)
  const { width = 1 } = useResizeObserver<HTMLDivElement>({ ref: svgRef })

  useEffect(() => {
    render(data, dataArea, svgRef, width)
  }, [data, svgRef.current, width])
  return <div ref={svgRef} className="w-full relative"></div>
}

export default FundChart
