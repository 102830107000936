import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faSignOutAlt,
  faEnvelope,
  faFileInvoiceDollar,
  faChartLine,
  faHome,
} from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink, Navigate } from 'react-router-dom'
import axios from 'axios'
import './navigation.scss'
import logo from '../../../portal/images/logo.png'
import { themeState } from '../../state/atoms/theme-state'
import { useRecoilState } from 'recoil'
import { Config } from '../../../config'
import { useMsal } from '@azure/msal-react'
import { loginRequest, signupRequest } from '@/portal/msal-config'
import { User } from '@/core/models/user'
import { userState } from '@/common/state/atoms/user-state'

const Navigation: React.FC = () => {
  const [theme] = useRecoilState(themeState)
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [user] = useRecoilState(userState)
  const { instance } = useMsal()
  const doLogout = () => {
    instance.logout(loginRequest)
    instance.logout(signupRequest)
  }

  if (!isLoggedIn) {
    return <Navigate to="/" />
  }
  return (
    <nav id="header" className="bg-white fixed w-full z-10 top-0 shadow">
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between my-4">
        <div className="pl-4 md:pl-0">
          <Link to="/">
            <img
              className="logo-image"
              src={theme.logo ? `${Config.ThemeImageDir}/${theme.logo}` : logo}
            />
          </Link>
        </div>
        <div className="pr-0 flex justify-end">
          <NavLink
            to="/"
            className={(props) => {
              return `${props.isActive ? 'active ' : ''}mr-6 mt-2 navLink`
            }}
          >
            <FontAwesomeIcon size="lg" className="mr-2" icon={faHome} />
            Home
          </NavLink>
          <NavLink
            to="/reports"
            className={(props) => {
              return `${props.isActive ? 'active ' : ''}mr-6 mt-2 navLink`
            }}
          >
            <span className="relative mr-2">
              <FontAwesomeIcon size="lg" icon={faChartLine} />
            </span>
            Maandrapportage
          </NavLink>
          <NavLink to="/documents" className="mr-6 mt-2 navLink">
            <FontAwesomeIcon
              size="lg"
              className="mr-2"
              icon={faFileInvoiceDollar}
            />
            Documenten
          </NavLink>
          <NavLink to="/messages" className="mr-6 mt-2 navLink">
            <span className="relative mr-2">
              <FontAwesomeIcon size="lg" icon={faEnvelope} />
              <span className="absolute top-0 right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                1
              </span>
            </span>
            Berichten
          </NavLink>
          <div className=" relative inline-block text-left dropdown">
            <span className="rounded-md shadow-sm">
              <button
                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-primary focus:outline-none focus:border-primary focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                type="button"
                aria-haspopup="true"
                aria-expanded="true"
                aria-controls="headlessui-menu-items-117"
              >
                <div>
                  <FontAwesomeIcon size="lg" icon={faUser} /> {user?.fullName}
                </div>
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </span>
            <div className="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
              <div
                className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                aria-labelledby="headlessui-menu-button-1"
                id="headlessui-menu-items-117"
                role="menu"
              >
                <ul className="px-4 py-3">
                  <li className="text-sm font-medium leading-5 truncate">
                    {user?.fullName}
                  </li>
                  <li className="text-sm font-medium leading-5 truncate">
                    {user?.email}
                  </li>
                </ul>
                <div className="py-1">
                  <a
                    href="#"
                    onClick={doLogout}
                    tabIndex={3}
                    className="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left hover:text-primary"
                    role="menuitem"
                  >
                    Afmelden <FontAwesomeIcon size="lg" icon={faSignOutAlt} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
